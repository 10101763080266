import { Component, input } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { NgClass } from '@angular/common';
import { InvoiceStatus, ProductStatus } from '../models';
import { OrderMilestone } from '@cportal/api';

@Component({
  selector: 'vbn-status-badge',
  standalone: true,
  imports: [MatChip, NgClass],
  templateUrl: './status-badge.component.html',
})
export class StatusBadgeComponent {
  status = input<OrderMilestone | InvoiceStatus | ProductStatus | undefined>();

  BadgeColor = new Map<OrderMilestone | InvoiceStatus | ProductStatus | undefined, string>([
    [OrderMilestone.Pending, 'strong-orange'],
    [OrderMilestone.Finalized, 'strong-orange'],
    [OrderMilestone.Shipped, 'strong-orange'],
    [OrderMilestone.Delivered, 'light-gray'],
    [OrderMilestone.Cancelled, 'vivid-red'],
    [InvoiceStatus.Due, 'strong-orange'],
    [InvoiceStatus.Paid, 'light-gray'],
    [InvoiceStatus.Overdue, 'vivid-red'],
    [ProductStatus.New, 'strong-orange'],
  ]);
}
